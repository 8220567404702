import { Component, EventEmitter, Input, Output } from "@angular/core";
import { VariableI } from "../../pojo/VariableI";
import { S25BpeVarsDropdownComponent } from "../s25-dropdown/single-select/s25.bpe.vars.dropdown.component";
import { LooseAutocomplete } from "../../pojo/Util";

@Component({
    selector: "s25-ng-table-builder",
    template: `
        <table>
            <thead>
                <tr>
                    <th>Column Name</th>
                    <th>Variable</th>
                    <th *ngIf="hasDataColumn">{{ dataColumnName }}</th>
                    <th *ngIf="hasWidthColumn" [style.width]="'5.5em'">
                        Width
                        <s25-ng-info-message [isPopover]="true">
                            This value is the width of the column <em>relative to other columns</em>. All of these
                            values are added together and the relative width of any individual column is its value
                            divided by the sum.
                        </s25-ng-info-message>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody s25-ng-dnd-sortable [items]="columns" (orderChanged)="emit()">
                <tr *ngFor="let column of columns; let i = index" s25-ng-dnd-sortable-item [index]="i">
                    <td>
                        <input
                            id="header{{ i }}"
                            class="c-input"
                            [(ngModel)]="column.header"
                            (ngModelChange)="onHeaderChange()"
                        />
                    </td>
                    <td>
                        <s25-bpe-vars-dropdown
                            [type]="variableType"
                            [(chosen)]="column.variable"
                            (chosenChange)="onVariableChange(column)"
                        ></s25-bpe-vars-dropdown>
                    </td>
                    <td *ngIf="hasDataColumn">
                        <s25-bpe-vars-dropdown
                            *ngIf="column.variable?.dataType === 'format'"
                            [type]="dateFormatType"
                            [(chosen)]="column.format"
                            [placeholder]="'Select a Format'"
                            (chosenChange)="onDataChange()"
                        ></s25-bpe-vars-dropdown>
                        <s25-ng-dropdown-search-criteria
                            *ngIf="column.variable?.dataType === 'searchCriteriaItem'"
                            [type]="$any(column.variable?.criterion)"
                            [(chosen)]="column.searchCriteriaItem"
                            (chosenChange)="onDataChange()"
                        ></s25-ng-dropdown-search-criteria>
                        <s25-generic-dropdown
                            *ngIf="column.variable?.dataType === 'options'"
                            [items]="column.variable?.options"
                            [(chosen)]="column.searchCriteriaItem"
                            (chosenChange)="onDataChange()"
                        ></s25-generic-dropdown>
                    </td>
                    <td *ngIf="hasWidthColumn">
                        <input
                            class="c-input"
                            type="number"
                            min="0"
                            [max]="maxWidth"
                            [(ngModel)]="column.width"
                            (ngModelChange)="onWidthChange()"
                        />
                    </td>
                    <td>
                        <button class="aw-button aw-button--danger--outline" (click)="onDeleteColumn(i)">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="aw-button aw-button--outline" (click)="onAddColumn()">Add Column</button>
    `,
    styles: `
        table {
            width: 100%;
            table-layout: fixed;
            margin-bottom: 0.5em;
        }

        table th:last-child {
            width: 80px;
        }

        table th,
        table td {
            padding: 0.25em;
        }

        table td > * {
            max-width: 100%;
        }

        [s25-ng-dnd-sortable-item][draggable="true"] {
            position: relative;
        }

        [s25-ng-dnd-sortable-item][draggable="true"] > td:first-child::before {
            content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMjQgMjQiPgogICAgPHRpdGxlPmRyYWctaW5kaWNhdG9yPC90aXRsZT4KICAgIDxjaXJjbGUgY3g9IjgiIGN5PSIxMiIgcj0iMSI+PC9jaXJjbGU+CiAgICA8Y2lyY2xlIGN4PSI4IiBjeT0iNSIgcj0iMSI+PC9jaXJjbGU+CiAgICA8Y2lyY2xlIGN4PSI4IiBjeT0iMTkiIHI9IjEiPjwvY2lyY2xlPgogICAgPGNpcmNsZSBjeD0iMTYiIGN5PSIxMiIgcj0iMSI+PC9jaXJjbGU+CiAgICA8Y2lyY2xlIGN4PSIxNiIgY3k9IjUiIHI9IjEiPjwvY2lyY2xlPgogICAgPGNpcmNsZSBjeD0iMTYiIGN5PSIxOSIgcj0iMSI+PC9jaXJjbGU+Cjwvc3ZnPgo=);
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            left: 0;
            top: 50%;
            translate: -1.25rem -50%;
        }
    `,
})
export class S25TableBuilderComponent {
    @Input({ required: true }) columns: TableBuilder.Column[];
    @Input({ required: true }) variableType: TableBuilder.VariableType;
    @Input() dateFormatType: TableBuilder.DateFormatType;
    @Input() hasDataColumn: boolean;
    @Input() hasWidthColumn: boolean;
    @Input() dataColumnName: string = "Data";
    @Input() maxWidth: number = 1_000;

    @Output() columnsChange: EventEmitter<TableBuilder.Column[]> = new EventEmitter<TableBuilder.Column[]>();

    constructor() {}

    emit() {
        this.columnsChange.emit(this.columns);
    }

    onHeaderChange() {
        this.emit();
    }

    onVariableChange(column: TableBuilder.Column) {
        delete column.format; // Clear format
        delete column.searchCriteriaItem; // Clear item
        if ("width" in column.variable) column.width = column.variable.width as number; // Use default width
        this.emit();
    }

    onDataChange() {
        this.emit();
    }

    onWidthChange() {
        this.emit();
    }

    onAddColumn() {
        this.columns.push({ header: "New Column", width: 1 });
        this.emit();
    }

    onDeleteColumn(index: number) {
        this.columns.splice(index, 1);
        this.emit();
    }
}

export namespace TableBuilder {
    export type VariableType = keyof typeof S25BpeVarsDropdownComponent.Items;
    export type DateFormatType = "tableDateFormats" | "tableDocumentDateFormats";

    export type Column = {
        header: string;
        math?: string;
        variable?: VariableI & { dataType?: DataType; criterion?: string };
        width?: number;
        format?: VariableI;
        searchCriteriaItem?: VariableI;
    };

    export type DataType = LooseAutocomplete<"format" | "searchCriteriaItem">;
}
