import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25EditableAbstract } from "../s25-editable/s25.editable.abstract";
import { S25ItemI } from "../../pojo/S25ItemI";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { EventService } from "../../services/event.service";
import { RoleService } from "../../services/role.service";

interface ContactRole {
    eventId: string;
    itemId: number;
    item: S25ItemI[];
    itemLabel: string;
    itemTypeId: number;
}

interface AssignedRole {
    role_id: number;
    role_name: string;
}

@TypeManagerDecorator("s25-ng-editable-contact-roles")
@Component({
    selector: "s25-ng-editable-contact-roles",
    template: `
        <div
            *ngIf="this.init"
            class="c-eventDetails--contactRoles"
            [ngClass]="!this.editable && 'notEditable'"
            aria-label="Event Contacts"
            tabindex="0"
        >
            <div
                *ngFor="let role of this.contacts; let last = last"
                class="rolesContainer"
                [ngClass]="{ 'last-child': last }"
            >
                <span class="evdd-item-label">{{ role.itemLabel }}:</span>
                <div
                    *ngIf="this.editable"
                    class="contactDropdown"
                    [ngClass]="role.itemId > 0 && this.addContactButton ? 'removeTrue' : ''"
                >
                    <s25-ng-dropdown-search-criteria
                        [type]="'contacts'"
                        [(chosen)]="role.item[0]"
                        (chosenChange)="updateRole(this.itemId, role.itemId, role.item[0].itemId)"
                        [popoverClass]="'qtip-content contactInfo'"
                    ></s25-ng-dropdown-search-criteria>
                </div>
                <button
                    *ngIf="this.editable && role.itemId > 0"
                    (click)="deleteRole(this.itemId, role.itemId)"
                    class="aw-button aw-button--danger--transparent"
                    [ngClass]="this.deleteContact && 'deleteTrue'"
                >
                    Remove
                </button>
                <s25-item-contact
                    *ngIf="!this.editable"
                    [modelBean]="role.item[0]"
                    [includeTypeIcon]="true"
                    class="editableFalse"
                ></s25-item-contact>
            </div>
            <div [hidden]="!this.editable">
                <div class="editButtons">
                    <button (click)="this.showDropdown()" class="aw-button aw-button--primary c-margin-top--quarter">
                        Edit Contacts
                    </button>
                    <select
                        *ngIf="this.addContactButton"
                        [(ngModel)]="this.roleToAdd"
                        (change)="addRole()"
                        class="cn-form__control"
                    >
                        <option *ngIf="this.allRolesAssigned" [ngValue]="undefined">All Contact Roles Assigned</option>
                        <option *ngIf="!this.allRolesAssigned" [ngValue]="undefined">Select a Contact Role</option>
                        <option *ngFor="let role of this.availableRoles" [ngValue]="role" class="ngListPageDropdown">
                            {{ role.role_name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableContactRolesComponent extends S25EditableAbstract implements OnInit {
    @Input() itemId: string;
    @Input() contacts: S25ItemI[];
    @Input() editable: boolean;

    @Output() rolesChange = new EventEmitter<any>();

    init: boolean = false;
    addContactButton: boolean = false;
    deleteContact: boolean = false;
    eventTypeRoles: AssignedRole[];
    allRolesAssigned: boolean;
    availableRoles: AssignedRole[];
    roleToAdd: AssignedRole;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
        this.elementRef.nativeElement.angBridge = this;
    }

    getType = () => "contacts";

    ngOnInit() {
        super.ngOnInit();

        this.updateAvailableRoles();

        this.contacts &&
            this.contacts.forEach((contact) => (contact.item[0].popoverClass = "qtip-content contactInfo"));

        this.init = true;
        this.cd.detectChanges();
    }

    updateAvailableRoles() {
        EventService.getEventType(S25Util.toInt(this.itemId))
            .then((data) => RoleService.getEventTypeRoles(data))
            .then((roles) => {
                this.eventTypeRoles = roles || [];
            })
            .then(() => {
                this.allRolesAssigned = this.eventTypeRoles.length === this.contacts.length;
                let filledRolesIds: number[] = [];
                this.contacts.forEach((contact: ContactRole) => filledRolesIds.push(contact.itemId));
                this.availableRoles = this.eventTypeRoles.filter(
                    (role: AssignedRole) => !filledRolesIds.includes(role.role_id),
                );
            });
    }

    updateRole(eventId: string, roleId: number, contactId: number) {
        let numericEventId = S25Util.toInt(this.itemId);
        return EventService.updateEventContactRole([numericEventId], roleId, contactId).then(
            (response) => {
                this.updateAvailableRoles();
                return response;
            },
            (error) => {
                S25Util.showError(error);
            },
        );
    }

    addRole() {
        if (this.roleToAdd) {
            const newRole: ContactRole = {
                eventId: this.itemId,
                itemId: this.roleToAdd.role_id,
                item: [],
                itemLabel: this.roleToAdd.role_name,
                itemTypeId: 1109,
            };

            this.contacts.push(newRole);
            this.addContactButton = !this.addContactButton;
            this.deleteContact = !this.deleteContact;

            this.cd.detectChanges();
        }
    }

    deleteRole(eventId: string, roleId: number) {
        let numericEventId = S25Util.toInt(this.itemId);
        EventService.updateEventContactRole([numericEventId], roleId, "")
            .then(() => {
                this.contacts = this.contacts.filter((role: ContactRole) => role.itemId !== roleId);
                this.allRolesAssigned = this.eventTypeRoles.length === this.contacts.length;
                this.addContactButton = !this.addContactButton;
                this.deleteContact = !this.deleteContact;
                this.roleToAdd = undefined;
                this.cd.detectChanges();
            })
            .then(() => {
                this.updateAvailableRoles();
            });
    }

    showDropdown() {
        this.addContactButton = !this.addContactButton;
        this.deleteContact = !this.deleteContact;
        this.allRolesAssigned = this.eventTypeRoles.length === this.contacts.length;
        this.cd.detectChanges();
    }
}
